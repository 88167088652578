/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  function cp_showModal(){
    $('#cp-newsletter-modal').modal({
      show: true,
    });
  }

  function cp_showModal_donations(){
    $('#cp-donations-modal').modal({
      show: true,
    });
  }

  function cp_newsletter_modal_timed() {
    if ($("body").data('showmodal')) {
      console.log("showmodal setting true // proceeding");
      if( $.cookie("mc_modal_seen") ){
        console.log('cookie already set // modal suspended temporarily');
      }else{
        cp_showModal(); // TEMPORARILY SUSPEND SHOWING NEWSLETTER MODAL, DEC 2020
        // cp_showModal_donations();
        console.log('modal seen // setting cookie and temporarily suspending');
        $.cookie('mc_modal_seen', true, { expires: 7 }); //timed, 7 days
        // $.cookie('mc_modal_seen', true, { expires: 1 }); //timed, 1 day
        // $.cookie('mc_modal_seen', true); //session
      }
    }else{
      console.log("showmodal setting false // removing cookies");
      $.removeCookie('mc_modal_seen');
    }
  }

  $('.modal-toggle--newsletter-form').click(cp_showModal); // show modal on click




  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        cp_newsletter_modal_timed()
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_template_forestmapper_appdisplay': {
      init: function() {
        // JavaScript to be fired on the forestmapper appdisplay page
        $('#fmPartnerModal').modal();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


  $(document).ready(function() {
    console.log("initializing adobe balancetext");
    balanceText();


      /*////////////////////////////////////////////////////////////////////////////////////
     // HEADER STICKING & UNSTICKING //////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////*/

    // Get the relevant elements variableized
    var eyebrow = $(".siteheader-eyebrow");

    var header = $(".siteheader-desktop");
    // console.log(header);
    var footer = $("#promo-newsletter-signup");
    // console.log(footer);

    // Get the offset position of the navbar
    // var headerPos = header.offset().top;
    // var footerPos = footer.offset().top;
    // console.log(headerPos);
    // console.log(footerPos);
    // console.log( $(document).scrollTop() );

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function cp_pin_header__desktop() {

      if ( $(document).scrollTop() > (eyebrow.offset().top + eyebrow.height()) ) {
        header.addClass("sticky");
        $('body').addClass("fixed-header");
      } else {
        header.removeClass("sticky");
        $('body').removeClass("fixed-header");
      }

      /* THRESHOLD-BASED UNSTICKING... ////////////////////////////////////////////////
      // IF the page is scrolled beyond the specified unsticking threshold element,
      // unstick the header and use jQuery to attach the header to the top
      // of that element, and resume scrolling it in its new position
      ///////////////////////////////////////////////////// */
      var footerScrollOffset = $(document).scrollTop() + header.height() -  footer.offset().top;

      if ( footerScrollOffset >= 0 ) {
        header.addClass("sticky--unstick-bottom");
        $('body').addClass("fixed-header--unstick-bottom");
        var footer_top_stick_pos = footer.offset().top - header.height();
        header.css({ top: footer_top_stick_pos });
      }else{
        header.removeClass("sticky--unstick-bottom");
        $('body').removeClass("fixed-header--unstick-bottom");
        header.css({ top: 0 });
      }
    }

    catsNav = $(".nav-single-post--cats");

    function cp_pin_post_cats_nav__desktop() {
      var footer_threshold_offset =  $(document).scrollTop() - footer.offset().top + $(window).height();
      console.log(footer_threshold_offset);
      if ( footer_threshold_offset >= 0 ){
        catsNav.removeClass("sticky-bottom");
      }else{
        catsNav.addClass("sticky-bottom");
        footer.addClass("catsNav-padding");
      }
    }
    cp_pin_post_cats_nav__desktop();

    // When the user scrolls the page, execute functions
    function cp_check_scroll_position() {
      cp_pin_header__desktop();
      cp_pin_post_cats_nav__desktop();
    }
    window.addEventListener('scroll', cp_check_scroll_position);


    // start that sweet logo animation a-playin'...
    var logovideo = document.getElementById("logo-video");
    logovideo.onloadeddata = function() {
      logovideo.play();
    };


    // init tooltips
    $('[data-toggle="tooltip"]').tooltip();

  }); //end sage & other custom docready functions



})(jQuery); // Fully reference jQuery after this point.



///////////////////////////////////////////////////////////////////
/// MODALS SHITE /////////////////////////////////
//////////////////////////////////
(function($) {



  ////////// FORESTMAPPER MODAL ///////////////////
  $('#fmModal').on('show.bs.modal', function (event) {
    console.log("launching a modal!");

    var button = $(event.relatedTarget); // Button that triggered the modal
    var recipient = button.data('whatever'); // Extract info from data-* attributes
    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.

    var ingest = button.data('ingest');
    var ingested_title = $(ingest +' .title').text();
    var ingested_content = $(ingest +' .content').html();
    console.log(ingest);


    var modal = $(this);
    // modal.find('.modal-title').text('New message to ' + recipient);
    // modal.find('.modal-body input').val(recipient);


    modal.find('.modal-title').html(ingested_title);
    modal.find('.modal-ingested-content').html(ingested_content);
  });




  ////////// COMPANIES MODAL ///////////////////

  $('#modal-companies').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget);
    var ingest = button.data('ingest'); //get url of target post
    // console.log(ingest);
    var modal = $(this);
    modal.find('.modal-body').load(""+ingest+" .ingestible-content"); //use AJAX to pull content from associated WP post
  });

  $('#modal-companies').on('hidden.bs.modal', function (event) {
    var modal = $(this);
    modal.find('.modal-body').html('<div class="spinwrap"><svg aria-hidden="true" data-prefix="fas" data-icon="circle-notch" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle-notch fa-w-16 fa-spin fa-lg"><path fill="currentColor" d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z" class="spinner"></path></svg></div>');

  });


})(jQuery); // Fully reference jQuery after this point.
